import { defineStore } from 'pinia'
import { useGlobalStore } from './global'
import useAppData from '@/composables/useAppData'
import Api from '@/utils/api'

export const useAppStore = defineStore('app', {
  state: () => ({
    appData: {},
    otherProducts: [],
    dashboardData: null,
    pageTitle: '',
  }),
  getters: {},
  actions: {
    async getDashboardData() {
      const { appId } = useAppData()
      const globalStore = useGlobalStore()

      try {
        globalStore.$patch({ isLoading: true })

        const response = await Api.get('/dashboard-structure-new', {
          params: {
            tenant_site_id: appId.value,
          },
        })

        this.dashboardData = response.data
        globalStore.$patch({ isLoading: false })
      }
      catch (err) {
        console.log(err)
        globalStore.$patch({ isLoading: false })
      }
    },
  },
  persist: true,
})
